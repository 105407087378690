<template>
  <div v-if="loading">
    Carregando...
  </div>
  <div
    class="c-row"
    v-else
  >
    <div class="ui-m-4 ui-mt-8 c-col">
      <!-- Título -->
      <div class="c-row ui-mb-4">
        <div class="c-col">
          <span class="c-text-h2 c-text-gray-400">
            Editar usuário
          </span>
        </div>
      </div>

      <!-- Dados readonly do aluno -->
      <div class="c-row ui-mb-8">
        <div class="c-col">
          <info-card :items="userData" />
        </div>
      </div>

      <p-form ref="form">
        <!-- Dados e endereço do aluno -->
        <div class="c-row ui-mb-8">
          <!-- Dados do aluno -->
          <div class="c-col md:c-col-1/2">
            <card>
              <!-- Título -->
              <div class="c-text-subtitle ui-mb-4">
                Dados do aluno
              </div>

              <!-- Nome Completo -->
              <div class="c-row ui-mb-4">
                <div class="c-col">
                  <p-input
                    label="Nome completo"
                    v-model="user.full_name"
                    :validator="validateName"
                  />
                </div>
              </div>

              <div class="c-row ui-mb-4">
                <!-- Email -->
                <div class="c-col">
                  <p-input
                    label="Email"
                    v-model="user.email"
                    :validator="validateEmail"
                  />
                </div>

                <!-- CPF -->
                <div
                  class="c-col"
                  style="flex-grow: 1;"
                >
                  <p-input
                    label="CPF"
                    v-model="user.cpf"
                    mask="000.000.000-00"
                    :validator="validateCPF"
                  />
                </div>
              </div>

              <div class="c-row ui-mb-4">
                <!-- Telefone -->
                <div
                  class="c-col"
                  style="flex-grow: 1;"
                >
                  <p-input
                    label="Telefone"
                    v-model="user.mobile"
                    mask="(00) 0 0000-0000"
                    :validator="validateMobile"
                  />
                </div>

                <!-- Data de nascimento -->
                <div
                  class="c-col"
                  style="flex-grow: 1;"
                >
                  <p-input
                    type="date"
                    label="Data de nascimento"
                    v-model="user.birthday"
                    :validator="validateBirthday"
                  />
                </div>
              </div>
            </card>
          </div>

          <!-- Endereço aluno -->
          <div class="c-col md:c-col-1/2">
            <card>
              <!-- Título -->
              <div class="c-text-subtitle ui-mb-4">
                Endereço
              </div>

              <!-- Rua/Avenida e Número -->
              <div class="c-row ui-mb-4">
                <!-- Rua/Avenida -->
                <div class="c-col">
                  <p-input
                    label="Rua/Avenida"
                    :value="user.address.streetName || '-'"
                    :disabled="true"
                  />
                </div>

                <!-- Número -->
                <div
                  class="c-col-full"
                  style="max-width: 140px;"
                >
                  <p-input
                    label="Número"
                    :value="user.address.number || '-'"
                    :disabled="true"
                  />
                </div>
              </div>

              <!-- Bairro e Complemento -->
              <div class="c-row ui-mb-4">
                <!-- Bairro -->
                <div class="c-col">
                  <p-input
                    label="Bairro"
                    :value="user.address.neighborhood || '-'"
                    :disabled="true"
                  />
                </div>

                <!-- Complemento -->
                <div
                  class="c-col-full"
                  style="max-width: 140px;"
                >
                  <p-input
                    label="Complemento"
                    :value="user.address.complement || '-'"
                    :disabled="true"
                  />
                </div>
              </div>

              <!-- Cidade, Estado e CEP -->
              <div class="c-row ui-mb-4">
                <!-- Cidade -->
                <div class="c-col">
                  <p-input
                    label="Cidade"
                    :value="user.address.city || '-'"
                    :disabled="true"
                  />
                </div>

                <!-- Estado -->
                <div
                  class="c-col-full"
                  style="max-width: 140px;"
                >
                  <p-input
                    label="Estado"
                    :value="user.address.state || '-'"
                    :disabled="true"
                  />
                </div>

                <!-- CEP -->
                <div
                  class="c-col-full"
                  style="max-width: 140px;"
                >
                  <p-input
                    label="CEP"
                    :value="user.address.zipCode || '-'"
                    mask="00000-000"
                    :disabled="true"
                  />
                </div>
              </div>
            </card>
          </div>
        </div>

        <!-- Status da conta -->
        <div class="c-row ui-mb-8">
          <div class="c-col">
            <card>
              <!-- Título -->
              <div class="c-text-subtitle ui-mb-4">
                Status
              </div>

              <!-- Status da conta -->
              <div class="c-row ui-mb-4">
                <div class="c-col c-d-flex">
                  <c-toggle
                    v-model="user.is_active"
                  />

                  <span class="c-text-b2 c-text-gray-300 ui-ml-2">
                    Conta ativa
                  </span>
                </div>
              </div>

              <!-- Fundador -->
              <div class="c-row ui-mb-4">
                <div class="c-col c-d-flex">
                  <c-toggle
                    v-model="user.is_questions_founder"
                  />

                  <span class="c-text-b2 c-text-gray-300 ui-ml-2">
                    Fundador do questões
                  </span>
                </div>
              </div>
            </card>
          </div>
        </div>

        <!-- Acessos do aluno -->
        <div class="c-row ui-mb-8">
          <!-- Questões -->
          <div class="c-col md:c-col-1/2">
            <card>
              <!-- Título -->
              <div class="c-text-subtitle">
                Questões
              </div>

              <!-- Explicação -->
              <div class="c-text-b3 ui-mb-4">
                Para vitalício, considerar 50 anos
              </div>

              <!-- Expiração do Questões -->
              <div class="c-row ui-mb-4 c-vertical-center">
                <div class="c-col">
                  <p-input
                    label="Data final de acesso"
                    :value="userQuestionsAccessExpiration"
                    @input="updateQuestionsDate"
                    type="date"
                  />
                </div>

                <div class="c-col">
                  <div class="c-row">
                    <div class="c-col">
                      <button
                        @click="add50YearsToQuestions()"
                        class="c-text-hyperlink c-text-gray-400"
                      >
                        Adicionar 50 anos
                      </button>
                    </div>
                  </div>

                  <div class="c-row">
                    <div class="c-col">
                      <button
                        @click="user.questions_access_expiration = null"
                        class="c-text-hyperlink c-text-orange-500"
                      >
                        Remover acesso
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>

          <!-- Cast -->
          <div class="c-col md:c-col-1/2">
            <card>
              <!-- Título -->
              <div class="c-text-subtitle">
                Cast
              </div>

              <!-- Explicação -->
              <div class="c-text-b3 ui-mb-4">
                Para vitalício, considerar 50 anos
              </div>

              <!-- Expiração do Cast -->
              <div class="c-row ui-mb-4 c-vertical-center">
                <div class="c-col">
                  <p-input
                    label="Data final de acesso"
                    :value="userCastAccessExpiration"
                    @input="updateCastDate"
                    type="date"
                  />
                </div>

                <div class="c-col">
                  <div class="c-row">
                    <div class="c-col">
                      <button
                        @click="add50YearsToCast()"
                        class="c-text-hyperlink c-text-gray-400"
                      >
                        Adicionar 50 anos
                      </button>
                    </div>
                  </div>

                  <div class="c-row">
                    <div class="c-col">
                      <button
                        @click="user.cast_access_expiration = null"
                        class="c-text-hyperlink c-text-orange-500"
                      >
                        Remover acesso
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>

        <!-- Comentários -->
        <div class="c-row ui-mb-8">
          <div class="c-col">
            <card>
              <div class="c-text-subtitle ui-mb-4">
                Comentários
              </div>

              <p-textarea
                data-qa="commentInput"
                name="comment"
                v-model="user.comment"
                class="ui-h-48"
              />
            </card>
          </div>
        </div>

        <!-- Botões -->
        <div class="c-row c-horizontal-center">
          <div class="c-col-1/4 c-d-flex ui-justify-end">
            <c-button
              full
              outline
              style="max-width: 164px;"
              @click="backToUserDetails()"
              :disabled="isFormSending"
            >
              Voltar
            </c-button>
          </div>

          <div class="c-col-1/4">
            <c-button
              full
              type="submit"
              style="max-width: 164px;"
              @click="updateUser"
              :loading="isFormSending"
            >
              Salvar
            </c-button>
          </div>
        </div>
      </p-form>
    </div>
  </div>
</template>

<script>
import { CButton, CToggle } from '@estrategiahq/coruja-web-ui';
import InfoCard from '@/components/info-card';
import Card from '@/components/card';
import PForm from '@/components/p-form';
import PInput from '@/components/p-input';
import PTextarea from '@/components/p-textarea';
import { historyBackTo } from '@/utils/historyBackTo';
import { format } from '@/utils/date';
import {
  validateName,
  validateEmail,
  validateMobile,
  validateCPF,
  validateBirthday,
} from '@/utils/validateUser';

const fullDateTimeFmt = new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' });
const isValidDate = (d) => d != null && !isNaN(new Date(d).getDate());
const toDate = (d) => isValidDate(d) ? new Date(d) : null;

export default {
  components: {
    PInput,
    PForm,
    Card,
    InfoCard,
    CToggle,
    CButton,
    PTextarea,
  },
  data () {
    return {
      loading: true,
      isFormSending: false,
      user: {
        address: {},
      },
    };
  },
  async mounted () {
    this.loading = true;
    const id = this.$route.params.id;
    this.user = await this.$s.user.getById(id);
    this.loading = false;

    await this.$nextTick();
    this.$refs.form?.valid({ dryRun: true, showErrors: true });
  },
  computed: {
    userQuestionsAccessExpiration () {
      return this.user.questions_access_expiration?.substring(0, 10);
    },
    userCastAccessExpiration () {
      return this.user.cast_access_expiration?.substring(0, 10);
    },
    userCreatedAt () {
      return this.user.created_at ? fullDateTimeFmt.format(new Date(this.user.created_at)) : null;
    },
    userData() {
      const createdAt = format(this.user.created_at, 'DD/MM/YYYY - HH:mm');
      const emailConfirmation = !this.user.require_email_confirmation ? 'SIM' : 'NÃO';

      return [
        { label: 'Criado em', value: createdAt },
        { label: 'ID do usuário', value: this.user.id },
        { label: 'ID Polvo', value: this.user.polvo_id },
        { label: 'Email confirmado', value: emailConfirmation },
      ];
    },
  },
  methods: {
    validateName,
    validateEmail,
    validateMobile(mobile) {
      return mobile === '' ? null : validateMobile(mobile);
    },
    validateCPF(cpf) {
      return cpf === '' ? null : validateCPF(cpf);
    },
    validateBirthday(date) {
      return date === '' ? null : validateBirthday(date);
    },
    isFormValid () {
      return this.$refs.form?.valid();
    },
    updateCastDate (value) {
      this.user.cast_access_expiration = toDate(value)?.toISOString();
    },
    updateQuestionsDate (value) {
      this.user.questions_access_expiration = toDate(value)?.toISOString();
    },
    add50YearsToCast () {
      const currentAccess = toDate(this.user.cast_access_expiration) || new Date();
      this.user.cast_access_expiration = new Date(currentAccess.setFullYear(currentAccess.getFullYear() + 50)).toISOString();
    },
    add50YearsToQuestions () {
      const currentAccess = toDate(this.user.questions_access_expiration) || new Date();
      this.user.questions_access_expiration = new Date(currentAccess.setFullYear(currentAccess.getFullYear() + 50)).toISOString();
    },
    async updateUser () {
      this.$refs.form?.valid({ dryRun: false });

      const valid = this.isFormValid();
      if (!valid) {
        this.$toast.show({ type: 'error', text: 'Verifique os campos com erro (em vermelho)' });
        return;
      }

      this.isFormSending = true;
      await this.$s.user.update(this.user).finally(() => {
        let msgSuccess = 'Dados atualizados com sucesso!';
        if (!this.user.is_active) {
          msgSuccess += ' As sessões ativas do usuário estão sendo expiradas em background e isso pode levar alguns minutos.';
        }
        this.$toast.show({ type: 'success', text: msgSuccess, timeout: 0 });
        this.isFormSending = false;
      });
    },
    backToUserDetails() {
      historyBackTo({
        router: this.$router,
        fallback: { name: 'user-detail', params: { id: this.user.id } },
      });
    },
  },
};
</script>
