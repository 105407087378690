<template>
  <div
    class="ui-w-full"
    :class="{ disabled }"
  >
    <div
      :class="[textareaState.border, disabledStyle]"
      class="c-d-flex ui-h-full ui-transition-all ui-in-out ui-duration-300 ui-justify-between ui-p-4 ui-rounded ui-overflow-hidden ui-antialiased c-bg-white"
    >
      <div class="ui-flex-grow ui-items-center ui-w-full ui-h-full ui-select-none">
        <textarea
          ref="textarea"
          class="ui-resize-none ui-w-full ui-h-full focus:ui-outline-none ui-text-input-value ui-font-medium c-text-input ui-transform ui-translate-y-input ui-block c-bg-transparent"
          :class="[disabledStyle]"
          :value="value"
          :disabled="disabled"
          @input="$emit('input', $event.target.value)"
          :placeholder="$attrs.placeholder"
          @focus="handleFocus"
          @blur="handleBlur"
          :name="name"
        />
      </div>

      <div
        v-if="loading"
        class="c-d-flex ui-justify-center ui-items-center"
      >
        <icon
          name="loading"
          outline
          color="#ccc"
          class="ui-spinner ui-animation-spin ui-animation-3s ui-animation-linear ui-animation-infinite"
          width="26"
          height="26"
        />
      </div>
    </div>
    <div
      v-if="value"
      :style="{ color: textareaState.infoColor }"
      class="c-d-flex ui-flex-row c-vertical-center ui-mt-1 ui-h-6 ui-select-none"
      data-qa="info"
    >
      <span
        :style="{ color: textareaState.infoColor }"
        class="c-text-b3 c-text-gray"
        data-qa="info-message"
      >
        {{ errorMessage || hint }}
      </span>
    </div>
  </div>
</template>

<script>
import { Icon } from '@estrategiahq/coruja-web-ui';

const textareaStates = {
  DEFAULT: {
    border: 'ui-border-gray-200 ui-shadow-input-default',
    color: 'var(--c-color-gray-300)',
    infoColor: 'var(--c-color-gray-300)',
  },
  FOCUS: {
    border: 'ui-border-primary-400 ui-shadow-input-focus',
    color: 'var(--c-color-primary-400)',
    infoColor: 'var(--c-color-gray-300)',
  },
  SUCCESS: {
    border: 'ui-border-green-400 ui-shadow-input-success',
    color: 'var(--c-color-green-400)',
    infoColor: 'var(--c-color-gray-300)',
  },
  ERROR: {
    border: 'ui-border-red-400 ui-shadow-input-error',
    color: 'var(--c-color-red-400)',
    infoColor: 'var(--c-color-red-400)',
  },
  DISABLED: {
    border: 'ui-border-gray-200 ui-shadow-input-default ui-opacity-32',
    color: 'var(--c-color-gray-300)',
    infoColor: 'var(--c-color-gray-300)',
  },
};

export default {
  name: 'PTextarea',
  components: {
    Icon,
  },
  props: {
    name: { type: String, default: '' },
    value: { type: String, default: '' },
    success: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    hint: { type: String, default: '' },
    errorMessage: { type: String, default: '' },
  },
  data: () => ({
    currentState: 'DEFAULT',
  }),
  computed: {
    disabledStyle() {
      return this.disabled ? 'ui-cursor-not-allowed ui-select-none ui-opacity-50' : '';
    },
    textareaState() {
      return textareaStates[this.currentState] || textareaStates.DEFAULT;
    },
  },
  created() {
    if (this.success) this.currentState = 'SUCCESS';
    if (this.error) this.currentState = 'ERROR';
    if (this.disabled) this.currentState = 'DISABLED';
  },
  watch: {
    success() {
      this.currentState = this.success ? 'SUCCESS' : 'DEFAULT';
    },
    errorMessage() {
      if (this.currentState !== 'FOCUS') {
        this.validate();
      }
    },
    disabled() {
      if (this.disabled) this.currentState = 'DISABLED';
    },
  },
  methods: {
    handleFocus() {
      if (this.disabled) return;
      this.currentState = 'FOCUS';
    },
    handleBlur() {
      this.validate();
    },
    validate({ dryRun = false } = {}) {
      if (!this.errorMessage) {
        if (!dryRun) this.currentState = 'SUCCESS';
        return true;
      }
      if (!dryRun) this.currentState = 'ERROR';
      return false;
    },
  },
};
</script>

<style scoped>
.disabled,
.disabled textarea {
  cursor: not-allowed !important;
}
</style>
